const modules : {
  [key: string] : {
    selector: string
    module: Function,
    config?: string,
    initialized?: HTMLElement[]
  }
} = {
  // 'transport': {
  //   'selector': '[data-transport]',
  //   'module': () => import('./modules/transport.ts'),
  //   'config': 'data-transport'
  // }
  'overlapping-section' : {
    'selector' : 'body.bricks-is-frontend .sb-overlapping-section',
    'module': () => import('./modules/overlapping-section')
  },
  'post-filter' : {
    'selector' : '.sb-filter-nav',
    'module': () => import('./modules/post-filter')
  }
};

const initialized : {
  [key : string] : Element[]
} = {};

// lets load some modules
const initialize = () => {
  for( let name in modules ){
    let config = modules[name];
    const elements = document.querySelectorAll( config.selector );
    
    elements.forEach( async el => {
      if( initialized[name] && initialized[name].includes( el ) ){
        return;
      }
      if( !initialized[name] ){
        initialized[name] = [];
      }
      initialized[name].push(el);
      let module = await config.module();
      try {
        let options = config.config ? JSON.parse( String(el.getAttribute( config.config ))) : {};
        module.default.init(el, options)
      }
      catch(e){
        console.log(e);
      }
    });
  }
};

addEventListener('DOMContentLoaded', initialize);